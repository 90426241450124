import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "../components/link"
import PageBuilder from "../components/page-builder"
import { StoryWithSummary } from "@hurleymc/components"
import Subnav from "../components/subnav"

const PageStoryWithSummary = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  const image =
    entry.images && entry.images.length > 0 && entry.images[0].lg
      ? entry.images[0].lg
      : null
  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      <Subnav entry={entry} />
      <StoryWithSummary
        components={{ Link }}
        header={entry.header}
        image={entry.images}
        linkText={entry.linkText}
        storyStatement={entry.storyStatement}
        summary={entry.summary}
        to={entry.to}
      />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query PageStoryWithSummary($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyGme", id: $id) {
        __typename
        title
        uri
        ... on Craft_gmePage_storyWithSummary_Entry {
          header
          images {
            ... on Craft_AssetInterface {
              title
              url
              lg: url(transform: "splashLg")
              xl: url(transform: "splashXl")
            }
          }
          linkText
          storyStatement
          storyStatementColor
          summary
          pageBuilder {
            ...PageBuilder
          }
          to {
            ...Link
          }
        }
      }
    }
  }
`

export default PageStoryWithSummary
